<template>
  <div class="smart-farm">
    <!-- 导航栏 -->
    <Nav :isHome="false"></Nav>
    <CommonNav pageName="智慧林业"></CommonNav>
    <section>
      <img class="smart-farm-bg" src="../../assets/images/forestry/bg.png" alt="">
    </section>
    <section class="smart-farm-container">
      <p class="smart-farm-head">与东北林业大学人工智能实验室针对数字林业项目实施产学研合作，针对林业碳汇信息共享平台开展自主研发。</p>
      <h4>林业碳汇信息共享平台</h4>
      <p class="smart-farm-article">为加强数字碳汇基础支撑研究，加大数字技术与碳汇应用拓展，完善碳汇制度和机制体系建设，四福科技开发了林业碳汇信息共享平台，旨在通过数字化管理，对各类碳汇实施精准监测、有效管理，覆盖碳汇监测、管理、交易、核查等诸多功能。该平台采用B/S（浏览器/服务器）模式进行设计，主要功能是展示和估算研究区碳汇信息，实现研究区碳汇信息的可视化。创建非结构型碳汇计量信息数据库，建立碳汇信息云共享平台，开发面向用户需求的自定义区域碳汇计量功能,进一步驱动碳汇交易数字化增长。</p>
      <h5 class="smart-farm-title">该平台核心技术及创新点：</h5>
      <p class="smart-farm-article">（1）高精度碳汇计量方法。研究基于遥感数据的森林类型分类方法，聚焦人工智能遥感影像分割技术，实现高精度森林类型分类；针对不同的林分类型及其碳汇能力差异，构建科学精准的碳汇计量方法模型，关联相应的雷达检测信息，完成不同林分类型碳汇量计算。</p>
      <p class="smart-farm-article">（2）碳汇动态监测方法。研究基于遥感数据的碳汇动态监测方法，构建基于遥感影像的森林动态变化监测模型，挖掘森林变化成因与碳汇计量的响应机制，建立森林碳汇智能动态更新体系。</p>
    </section>
    <section class="smart-farm-footer">
      <img src="../../assets/images/forestry/imgFirst.png" alt="">
    </section>
     <!-- 底部 -->
     <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
import CommonNav from '@/components/commonNav.vue'
import Footer from '@/components/footer.vue'

export default {
  data() {
    return {

    }
  },
  components: {
    Nav,
    CommonNav,
    Footer
  },
  beforeDestroy(){
    window.scrollTo(0,0)
  }
}
</script>

<style lang="scss" scoped>
.smart-farm{
  width: 100vw;
  padding-top: 120px;
}
.smart-farm-bg {
  width: 100%;
  height: 100%;
}
.smart-farm-container{
  width: 55%;
  max-width: 1057px;
  margin: 106px auto 75px;
  font-size: 22px;
  color: #808080;
  line-height: 2;
  text-align: left;
  .smart-farm-head{
    text-align: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }
  h4{
    height: 45px;
    font-size: 30px;
    color: #202020;
    font-weight: 500;
    padding-left: 10px;
    border-left: 3px solid #C7020A;
    margin-top: 30px;
    line-height: 45px;
  }
  .smart-farm-article{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
    // text-align-last: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }
  .smart-farm-title{
    color: #C7050F;
    margin-bottom: 20px;
    font-weight: 500;
  }
  h5{
    font-weight: 400;
    font-size: 22px;
  }
}
.smart-farm-footer{
  width: 55%;
  max-width: 1057px;
  margin: 0 auto 107px;
  text-align: center;
  img{
    width: 94%;
  }
}
</style>